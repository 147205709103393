import { useEffect, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { RouterProvider } from 'react-router-dom';
import routes from "./routes/Router";
import { QueryClient, QueryClientProvider } from 'react-query'

import { useTheme } from "./hooks/useTheme";
import Logo from "./components/Logo";
import Impersonation from './components/Impersonation';

import { fetchCompanies } from './features/companies/data/companyData'
import { fetchPermissionChange } from './data/users/data/hasPermissionChangeData'
import { updatePermissionChange } from './data/users/data/updatePermissionChangeData'
import { writeAudit } from './features/audit/data/writeAudit'

// Toastify imports
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Following along with the
 * tutorial https://ui.docs.amplify.aws/react/guides/auth-protected?
 * Update this import to match the path to your aws-exports.js file:
 * import aws_exports from "./aws-exports ";
 */
//Authenticator
import { Amplify } from 'aws-amplify';
import { Authenticator, Button, Heading, Text, View, Link } from '@aws-amplify/ui-react';

import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, fetchAuthSession, getCurrentUser, fetchUserAttributes, signOut } from 'aws-amplify/auth';

import useStore from './store';
import { ConfirmationProvider } from './hooks/useConfirmation';
import ConfirmationDialog from './components/ConfirmationDialog';
import GreetingModal from './components/GreetingModal';
import { getLocalStorage, setLocalStorage } from './hooks/appVariables';


Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      /* identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID, */
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
          scopes: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
          ],
          // can wrap this in if statement - https://github.com/aws-amplify/amplify-cli/issues/2792
          // if (!isLocalhost) {
          redirectSignIn: [process.env.REACT_APP_AWS_OAUTH_REDIRECT_URI],
          redirectSignOut: [process.env.REACT_APP_AWS_OAUTH_REDIRECT_URI],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  }
});

export default function App() {
  
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const toggleTab = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
  }

  const queryClient = new QueryClient()

  const routing = routes()
  const theme = useTheme()

  const user = getLocalStorage(process.env.REACT_APP_USER_KEY)
  const company = getLocalStorage(process.env.REACT_APP_COMPANY_KEY)

  const [myConfirmSignupShow, setMyConfirmSignUpShow] = useState(false)

  //modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const MINUTE_MS = 60000; // 1 minute - wanted to use environment variable but it was not working

  // Check session every minute to ensure user is still logged in
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      console.log('checking session: ', MINUTE_MS, 'ms');
      if (!getLocalStorage(process.env.REACT_APP_USER_KEY)) return;
      fetchUserAttributes(getCurrentUser())
        .then((response) => {
          console.log('checked session', response);
        })
        .catch((error) => {
          console.log('session error: ', error);
          signOut();
        });
      clearPermissionChange();
    }, MINUTE_MS);
    return () => clearInterval(interval); // Cleanup interval on unmount or when user signs out
  }, []);


  async function SSOLogin() {
    console.log("Starting Login")
    try {
      await signInWithRedirect({ provider: { custom: process.env.REACT_APP_AWS_IDP } })
    } catch (error) {
      console.error("SAML Federated Sign-In Error:", error);
    }
  }

  /*****************************************************
  This routine checks to see if the hasPermissionChange
  field against the user in the database is set to true.
  If it is, then we:
  1. Removes the existing company
  2. Retrieves a fresh list of companies
  3. Sets the current or default company in local storage
  4. Sets the hasPermissionChange back to false
  5. Refreshes the browser
  *****************************************************/
  async function clearPermissionChange() {
    if (!getLocalStorage(process.env.REACT_APP_USER_KEY)) return
    if (!getLocalStorage(process.env.REACT_APP_COMPANY_KEY)) return
    var hasChanged = await fetchPermissionChange(user.emailAddress)
    if (hasChanged.hasPermissionChange) {
      try {
        const companyId = company.companyId
        localStorage.removeItem(process.env.REACT_APP_COMPANY_KEY)
        const companies = await fetchCompanies()
        let newCompany = companies.find(c => c.id === companyId)
        if (!newCompany) {
          newCompany = companies.find(c => c.isDefault === true)
        }
        setLocalStorage(process.env.REACT_APP_COMPANY_KEY, newCompany)
        await updatePermissionChange(user.emailAddress, false)
        const location = window.location
        window.location = location
      } catch (error) {
        console.error("clearPermissionChange Error:", error)
      }
    }
  }

  //https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced
  const components = {
    Header() {
      return (
        <div className="my-5">
          <Logo className="mt-5"></Logo>
        </div>
      );
    },
    SetupTotp: {
      Header() {
        return (
          <div>
            <Heading
              level={3}
            >
              Setup Multi-Factor Authentication
            </Heading>
            <br />
            <Heading
              level={8}
            >
              Please scan the QR code below with your preferred Authenticator App.
            </Heading>
          </div>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <Heading
            level={3}
          >
            Enter confirmation code
          </Heading>
        );
      },
    },

    SignIn: {
      // Header() {
      //   return (
      //     <>
      //       <form data-amplify-form style={{ paddingTop: '0', height: '100px', width: 'auto' }}>
      //         <Button className="amplify-button amplify-field-group__control amplify-button--outline amplify-button--fullwidth"
      //           style={{ margin: '2rem 0' }}
      //           onClick={() => SSOLogin()} >
      //           Login with SSO
      //         </Button>
      //       </form>
      //       <div style={{ marginTop: '1rem' }}>
      //         <div className="amplify-flex federated-sign-in-container"
      //           style={{ flexDirection: 'column', padding: '0px 0px 1rem' }}>
      //           <hr aria-orientation="horizontal" className="amplify-divider amplify-divider--horizontal amplify-divider--small amplify-divider--label" data-size="small" data-label="or" />
      //         </div>
      //       </div>
      //     </>
      //   );
      // },
    },
    Footer() {
      return (
        <div className="my-5">
          <Button style={{ width: '100%' }} color="primary" onClick={toggle} className='amplify-button--link amplify-button--small'>
            Having issues logging in?
          </Button>
          <Text level={2} className="text-center">

          </Text>
        </div>
      )
    },
  }

  const setEmail = useStore((state) => state.setEmail)
  const removeEmail = useStore((state) => state.removeEmail)
  const removeUser = useStore((state) => state.removeUser)
  const removeCurrentUser = useStore((state) => state.removeCurrentUser)

  //https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/#listen-to-and-log-auth-events
  const listener = (data) => {
    switch (data?.payload?.event) {
      case 'signUp':
        auditEvent("User signup", "Successful");
        setMyConfirmSignUpShow(true)
        console.log('user signed up');
        break;
      case 'confirmSignUp':
      case 'signIn':
        auditEvent("User confirmation", "Successful");
        setMyConfirmSignUpShow(false)
        console.log('user confirmation successful');
        break;
      case 'signedIn':
        auditEvent("Login", "Successful", data?.payload?.data?.signInDetails?.loginId);
        console.log('user signed in');
        break;
      case 'cognitoHostedUI':
        auditEvent("User authentication", "Successful");
        console.log('Authenticated...');
        //console.log(token);
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        auditEvent("Login", "Failed");
        console.log('Error', data);
        break;
      case 'signedOut':
        auditEvent("Logout", "Successful", useStore.getState().email);
        removeEmail();
        removeUser();
        removeCurrentUser();
        console.log('user signed out');
        break;
      default:
        //setMyConfirmSignUpShow(false)
        console.log('unknown event type: ', data?.payload?.event);
        break;
    }
  }

  const formFields = {
    setupTotp: {
      QR: {
        totpIssuer: 'Maintel Portal',
      },
    },
  }

  const auditEvent = (eventName, eventResult, loginId) => {

    // On login, the user has not been stored, but the email address is included in the event data. On logout, we can obtain the user's email address from the email in the store
    let emailAddress = loginId === undefined ? "" : loginId
    let entityId = 0

    if (user && emailAddress === "") {
      if (user?.emailAddress?.length > 0) {
        emailAddress = user.emailAddress
      }
      if (user?.id > 0) {
        entityId = user.id
      }
    }

    // There is no point in logging an event if we cannot identify the user, which is possible. For example, two
    // logout events are fired on logout. The second one fires after the user and email has been cleared, and
    // contains no email address in the event data. 
    if (entityId > 0 || emailAddress !== "") {
      let auditData = {
        "SourceApp": "Portal",
        "Action": eventName,
        "Result": eventResult,
        "Detail": {"Email": emailAddress},
        "EntityId": entityId
      }
      writeAudit(auditData)
    }
  }

  Hub.listen('auth', listener)

  // To convert the template theme name to the toast theme name
  const themeName = theme.replace('-mode', '')

  return (
    <div className={theme}>
      <Authenticator.Provider>
        <View>
          <Authenticator components={components} loginMechanisms={['email']} hideSignUp formFields={formFields}>
            {({ signOut, user }) => (
              <QueryClientProvider client={queryClient}>
                <Impersonation>
                  <ConfirmationProvider>
                    <main>
                      {setEmail()}
                      {/* <h1>Hello {user.username}</h1>
                    <button onClick={signOut}>Sign out</button> */}
                      <RouterProvider router={routing} />
                      <ToastContainer
                        className="main-toast-container"
                        position="top-right"
                        theme={themeName}
                        autoClose={4000}
                      />
                      <GreetingModal />
                    </main>
                    <ConfirmationDialog />
                  </ConfirmationProvider>
                </Impersonation>
              </QueryClientProvider>

            )}
          </Authenticator>
        </View>
      </Authenticator.Provider>
      <Modal isOpen={modal} toggle={toggle} size="xl" scrollable>
        <ModalHeader toggle={toggle}>Login Assistance</ModalHeader>
        <ModalBody>
          <Row>
            <Col style={{ display: 'inline-flex' }}>
              <h6 data-testid="loginhelp-tab1" className={currentActiveTab === "1" ? "custom-nav-selected custom-nav m-3" : "custom-nav m-3"} style={{ cursor: 'pointer' }}
                onClick={() => { toggleTab("1"); }}>
                Setting Up Your Authenticator
              </h6>
              <h6 data-testid="loginhelp-tab2" className={currentActiveTab === "2" ? "custom-nav-selected custom-nav m-3" : "custom-nav m-3"} style={{ cursor: 'pointer' }}
                onClick={() => { toggleTab("2"); }}>
                Using Your Authenticator
              </h6>
              <h6 data-testid="loginhelp-tab3" className={currentActiveTab === "3" ? "custom-nav-selected custom-nav m-3" : "custom-nav m-3"} style={{ cursor: 'pointer' }}
                onClick={() => { toggleTab("3"); }}>
                Troubleshooting
              </h6>
            </Col>
          </Row>
          {currentActiveTab === "1" ?
            <>
              <p>
                Setting up an authenticator app is a simple yet crucial step to enhance the security of your account. By following these easy instructions, you'll be able to link your account to an authentication app, ensuring that only you have access to your sensitive information. This guide will walk you through each step, making the process quick and straightforward. Let's get started on securing your account!
              </p>
              <h4>1. Choose an Authenticator App</h4>
              <ul>
                <li>Microsoft Authenticator</li>
                <li>Google Authenticator</li>
                <li>Authy</li>
                <li>LastPass Authenticator</li>
              </ul>

              <h4>2. Install the Authenticator App</h4>
              <p>Download and install the app on your mobile device from the App Store (iOS) or Google Play Store (Android).</p>

              <h4>3. Scan the QR Code</h4>
              <ol>
                <li>Open your authenticator app on your mobile device.</li>
                <li>In the authenticator app, choose to add a new account. This option might be represented by a "+" sign or "Add" button.</li>
                <li>Use your phone’s camera to scan the QR code displayed on your Maintel Portal screen.</li>
              </ol>

              <h4>4. Backup Code</h4>
              <p>In case you lose access to your authenticator app copy and save the backup code beneath the QR code in a secure location.</p>

              <h4>5. Enter the Verification Code</h4>
              <p>Once you scan the QR code, the authenticator app will display a 6-digit code. Enter this code in the "Code" field on your Maintel Portal screen to verify the setup.</p>

              <h4>6. Confirm Setup</h4>
              <ol>
                <li>Follow any additional prompts to confirm the setup.</li>
                <li>The service might ask you to enter another verification code from the authenticator app to finalize the process.</li>
              </ol>

              <h4>7. Test the Setup</h4>
              <ol>
                <li>Log out of your Maintel Portal account.</li>
                <li>Log back in to ensure that MFA is working correctly.</li>
                <li>You should be prompted to enter a code from your authenticator app after entering your password.</li>
              </ol>

              <h4>For Users Without Access to a Phone</h4>
              <p>Not to worry if you don't have a phone! You can still enhance your account security using <a href="https://authenticator.cc" target="_blank" rel="noreferrer">Authenticator.cc</a>. </p>
              <p> This web-based authenticator allows you to set up and use multi-factor authentication (MFA) directly from your computer. Follow the steps on their site located <a href="https://authenticator.cc/docs/en/quickstart" target="_blank" rel="noreferrer">here</a> to link your account, and you'll be able to generate verification codes without needing a mobile device.</p>
            </>
            : <></>}
          {currentActiveTab === "2" ?
            <>
              <p>An authenticator app is a powerful tool to enhance the security of your online accounts by providing an additional layer of verification. This guide will walk you through the steps of logging in using an authenticator app and understanding the expiry of the authentication codes.</p>

              <h4>1. Logging In</h4>
              <p>Enter your username and password as usual.</p>
              <p>Open your authenticator app.</p>
              <p>Enter the 6-digit code generated by the app into the MFA prompt on the website.</p>

              <h4>2. Code Expiry</h4>
              <p>Authenticator codes are time-based and usually refresh every 30 seconds. Ensure you enter the code within this timeframe.</p>
            </>
            : <></>}
          {currentActiveTab === "3" ?
            <>
              <p>Even with a properly set up authenticator app, you may encounter some issues. Here are common problems and their solutions to help you troubleshoot and resolve these issues effectively.</p>
              <h4>1. Code Mismatch Error</h4>
              <p>Once you have successfully set up your authenticator app, you will no longer be prompted with the QR code. If you are still seeing the QR code but have already paired your authenticator, you will need to remove the existing account in your authenticator app and scan the QR code again to re-add your account with the newly generated QR code.</p>

              <h4>2. Time Sync Issues</h4>
              <p>If codes are not working, ensure your phone's time is synchronized accurately with the internet time.</p>

              <h4>3. Lost or Reset Phone</h4>
              <p>Use backup codes to access your account and set up the authenticator on a new device. Contact the service provider's support if backup codes are unavailable.</p>

              <h4>4. Multiple Devices</h4>
              <p>Some authenticator apps allow you to use the authenticator on multiple devices. Set this up in the app settings using the backup code.</p>              
            </>
            : <></>}
            <br />
            <p><strong>If you are still having issues after trying these steps, please contact our support team.</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {myConfirmSignupShow ? (
        <>
          <div data-amplify-authenticator style={{ 'marginTop': '0rem' }}>
            <div data-amplify-container>
              <div data-amplify-router>
                <div dir="ltr" data-orientation="horizontal">
                  <div data-amplify-router-content="">
                    <div>
                      <form data-amplify-form style={{ height: '480px', width: 'auto' }}>
                        {/* <div style={{ width: 'auto' }} className='container-fluid text-center my-3'>
                          <i className="bi bi-person-exclamation display-1 primary"></i>
                        </div> */}
                        <Heading level={4}>Pending Signup Confirmation</Heading>
                        <div style={{ height: '2rem' }}></div>
                        <Text level={2}>Please contact an administrator so we can verify your account and get you all setup.</Text>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (null)}
    </div>
  );
};
